import React from "react";
import { Modal, Button } from "antd";
import { useSelector } from "react-redux"
import header_bg from "../../assets/images/modal_header_bg.png";
import Style from "./index.module.scss";
import customer from '../../assets/images/customer.png';

export default function CustomModal(props) {
  const newProps = {
    ...{ isModalOpen: false, handleCancel: () => { }, handleOk: () => { } },
    ...props,
  };
  const platform_device = useSelector(state => state.platform_device)
  const showMobile = platform_device=='Mobile'

  const handleOk = () => {
    newProps.handleOk();
  };
  const handleCancel = () => {
    newProps.handleCancel();
  };

  const customHeader = {
    boxSizing: "border-box",
    padding: "16px 27px",
    textAlign: "center",
    height: "56px",
    borderRadius: "0",
    backgroundImage: "url(" + header_bg + ")",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    color: "#fff",
    fontSize: "14px",
  };
  const customStyle = {
    footer: {
      backgroundColor: "#fff",
      height: "52px",
      lineHeight: "52px",
      boxSizing: "border-box",
      padding: !showMobile?"0 24px":"0 16px",
      borderTop: !showMobile?"1px solid #e8e8e8":"",
      position: "absolute",
      bottom: "0",
      left: "0",
      right: "0",
      borderRadius: showMobile?"0 0 8px 8px":""
    },
    body: {
      minHeight: "200px",
      padding: "56px 0",
    },
  };
  return (
    <Modal
      wrapClassName={Style["custom-modal"]}
      title={null}
      width={newProps.width || 520}
      closable={false}
      open={newProps.isModalOpen}
      onOk={handleOk}
      styles={customStyle}
      className={Style.modalContainer}
      onCancel={showMobile?handleCancel:()=>{}}
      footer={[
        <Button
          key="ok"
          style={{ backgroundColor: "#0052D9", color: "white" }}
          onClick={handleOk}
          className={Style.modalContainer__confirmBtn}
        >
          好的
        </Button>,
      ]}
    >
      <div style={customHeader} className={Style.modalContainer__header}>
        <span>{props.modalTitle ? props.modalTitle : "标普&榜置支持"}</span>
        { !showMobile
            ?<span
              style={{
                float: "right",
                cursor: "pointer",
                fontSize: "18px",
                position: "relative",
                bottom: "3px",
              }}
              onClick={handleCancel}
            >
              ×
            </span>
          :null
        }
      </div>
      <div className={Style["custom-modal-body"]}>{props.children}</div>
      { props.wechatQRCode
          ?<div className={Style.modalContainer__wechatQRCode_wrapper}>
            <div className={Style.modalContainer__wechatQRCode_content}>
              <div>{props?.otherTips?props.otherTips:'如有使用疑问或需添加更多功能请联系：'}</div>
              <div className={Style.modalContainer__wechatQRCode_scan}>扫码添加微信</div>
              <img className={Style.modalContainer__wechatQRCode_img} src={customer} alt=''/>
              <div>email：wangyilun@powerlaunch.cn</div>
              <div>联系电话：17610137961</div>
            </div>
          </div>
          :null
      }
    </Modal>
  );
}