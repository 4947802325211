import React from 'react'
import s from './additionalIndicators.module.scss'
import ReactEcharts from 'echarts-for-react'
// 创建一个格式化器实例
const formatter = new Intl.NumberFormat('en-US', {});

export default function AdditionalIndicators({ data }) {
    const getOption = (data) => {
        return {
            tooltip: {
                trigger: 'axis', // x轴触发
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'none'          // 默认为直线，可选为：'line' | 'shadow' | 'cross' | 'none'
                },
                formatter: function (params) {
                    let list = []
                    let listItem = ''
                    params.forEach((item) => {
                        if (item.value !== 0) {
                            list.push(item.marker + ' ' + item.seriesName + '： ' + ' ' + formatter.format(item.value))
                        }
                    })
                    listItem = list.join('<br/>')
                    return '<div>' + listItem + '</div>'
                }
            },
            legend: {
                orient: 'horizontal', // 水平排列
                x: 'center', //可设定图例在左、右、居中
                y: 'top', //可设定图例在上、下、居中
                type: 'scroll',
            },
            xAxis: {
                type: 'category',
                data: ['其他占比', '本公司']
            },
            grid: {
                left: '1%',     // 距离左边的距离，可以是像素或百分比
                right: '1%',    // 距离右边的距离
                bottom: '1%',   // 距离底部的距离
                top: '10%',     // 距离顶部的距离
                containLabel: true // 是否包含标签
            },
            yAxis: {},
            series: getSeriesData(data)
        };
    }
    const getSeriesData = (item) => {
        let obj = {}
        let arr = []
        item.yData.map((i, inx) => {
            obj[i] = item.xData[inx]
        })
        const sortArr = item.yData.slice(0, 3).sort((a, b) => Number(b)- Number(a))
        sortArr.map(i => {
            arr.push({
                type: 'bar',
                name: obj[i],
                data: [i, 0],
            })
        })
        arr.push({
            type: 'bar',
            name: item.xData[3],
            barGap: "-100%", // 平移自身一个身位，使之与前一个柱状图重叠
            data: [0, item.yData[3]],
        })
        return arr
    }
    return (
        <div className={s.echartsList}>
            {data.map((item, index) => {
                return <div key={index} className={s.echartsItem}>
                    <div className={s.echartsTitle}>{item.type}</div>
                    <ReactEcharts
                        option={getOption(item)}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{ height: '400px' }}
                    />
                </div>

            })}
        </div>
    )
}
