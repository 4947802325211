import React,{ useEffect, useState } from "react";
import style from "./index.module.scss";
import ReactECharts from "echarts-for-react";
import { Spin } from "antd";
import arrowRight from "@/assets/icons/arrowright.png";
import indicate from "@/assets/images/riskMonitor/indicate.png";
const ChartAnalysis = (props) => {
  const { data = {}, options = {} } = props;
  const [ name,setName ] = useState();
  const [ nameNum,setNameNum ] = useState();

  useEffect(()=>{
    if(props.flowName) {
      let newName = props.flowName.split("/");
      console.log(newName,props.flowName);
      setNameNum(newName[1])
      setName(newName[0]);
    }
  })
  return (
    <div className={style.chartAnalysis_container}>
      <div className={style.chartAnalysis_wrapper}>
        <div className={style.chartAnalysis_title}>
          <div className={style.chartAnalysis_title_main}>{name} {nameNum && <span className={style.chartAnalysis_title_main_desc}>{nameNum}</span>} </div>
          {props.chooseZb ? (
            <div
              className={style.chartAnalysis_title_link}
              onClick={() => {
                props.changeCurrentSelectId(
                  null,
                  null,
                  undefined,
                  undefined,
                  undefined
                );
              }}
            >
              所有指标{" "}
              <img
                src={arrowRight}
                style={{ marginLeft: "8px" }}
                width={14}
                height={14}
              />
            </div>
          ) : null}
        </div>
        <div style={{ width: "100%", height: "235px" }}>
          <Spin spinning={props.flowLoading}>
            <div style={{ width: "100%", height: "220px" }}>
              <ReactECharts
                style={{ width: "100%", height: "100%" }}
                option={options}
                key={props.chartKey}
                notMerge={true}
              />
            </div>

            {options.series && options.series[0].type === "bar" ? (
              <div className={style.indicateBox}>
                <div className={style.indicate}>
                  <div className={style.strong}>强</div>&nbsp;
                  <div className={style.img}>
                    <img src={indicate} alt="" />
                  </div>&nbsp;
                  <div className={style.weak}>弱</div>
                </div>
              </div>
            ) : null}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ChartAnalysis;