import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import {Spin} from "antd";

const ChartTable = (props) => {
  const {data = {}, options = {}} = props;
  const [ name,setName ] = useState();
  const [ nameNum,setNameNum ] = useState();

  useEffect(()=>{
    if(props.flowName) {
      let newName = props.flowName.split("/");
      console.log(newName,props.flowName);
      setNameNum(newName[1])
      setName(newName[0]);
    }
  })
  let result = props.data
  let isBank = result?.sector === '金融机构' && (result?.doc.industrySubcategory === '政策性银行/开发性金融机构' || result?.doc.industrySubcategory === '其他')
  return (
    <div className={style.chartAnalysis_container}>
      <div className={style.chartAnalysis_wrapper}>
        <div className={style.chartAnalysis_title}>
          <div className={style.chartAnalysis_title_main}>{name} {nameNum && <span className={style.chartAnalysis_title_main_desc}>{nameNum}</span>} </div>
        </div>
        <div style={{width: "100%", height: "235px"}}>
          <Spin spinning={props.flowLoading}>
            {
              isBank ? <div style={{width: "100%", height: "220px"}}>
                <div className={style.newBox}>
                  <div className={style.item}>
                    <div>业务状况</div>
                    <div
                      className={style.values}>{props.data?.doc?.businessProfile || props.data?.doc?.businessProfile}</div>
                  </div>
                  <div className={style.item}>
                    <div>资本与盈利性</div>
                    <div className={style.values}>{props.data?.doc?.capitalProfitability || '不适用'}</div>
                  </div>
                  <div className={style.item}>
                    <div>风险状况</div>
                    <div className={style.values}>{props.data?.doc?.riskProfile || '不适用'}</div>
                  </div>
                  <div className={style.item}>
                    <div>融资与流动性</div>
                    <div className={style.values}>{props.data?.doc?.financingLiquidity || '不适用'}</div>
                  </div>
                </div>
              </div> : <div style={{width: "100%", height: "220px"}}>
                <div className={style.newBox}>
                  <div className={[style.item, style.border].join(' ')}>
                    <div>业务状况</div>
                    <div className={style.values}>{props.data?.doc?.brp || props.data?.doc?.businessProfile}</div>
                  </div>
                  <div className={style.desc}>
                    <div>行业风险</div>
                    <div className={style.values}>{props.data?.doc?.industryRisk || '不适用'}</div>
                  </div>
                  <div className={style.desc}>
                    <div>竞争地位</div>
                    <div className={style.values}>{props.data?.doc?.competitivePosition || '不适用'}</div>
                  </div>
                </div>
                <div className={style.newBox} style={{marginTop: '10px'}}>
                  <div className={style.item}>
                    <div>财务风险</div>
                    <div className={style.values}>{props.data?.doc?.frp || '不适用'}</div>
                  </div>
                </div>
              </div>
            }
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ChartTable;