import React, { useEffect, useState } from "react";
import api from "@/api";
import { deepCopy } from "@/utils";
import style from "./index.module.scss";
import { useSearchParams } from "react-router-dom";
import OldDetail from './comps/oldDetail'
import BankDetail from './comps/oldDetail/bankDetail'
import InsuranceDetail from './comps/oldDetail/insuranceDetail'
import {
  detailNumberValue,
  getLastYearMonthArray,
  comparTwoData,
} from "@/utils";
let isSmillScreen = false
const QueryView = (props) => {
  const [isFIType, setIsFIType] = useState(false);
  const [searchParams] = useSearchParams(); // 获取查询参数
  const companyId = searchParams.get("id");
  const industriesId = searchParams.get("industries");
  const qualityId = searchParams.get("quality");
  const [chartKey, setChartKey] = useState(true);
  const [currentAllData, setCurrentAllData] = useState({});
  const [currentRardaData, setCurrentRardaData] = useState([]);
  const [ pdfData, setPdfData ] = useState()
  const getDetailPdf = async () => {
	  const result = await api.getRecordDetail({ companyId })
	  setPdfData(result.data)
  }
  /**
   * @description: 获取公司详情
   * @return {*}
   */
  const getCompanyDetailInfo = async () => {
    const result = await api.getCreditAnalysisDetail({ companyId });
    if (result?.code == 200) {
      setIsFIType(result.data?.sector == "金融机构");
      const list = result.data?.sector == "金融机构"
        ? (result.data?.doc?.entityFinancialData ?? {},
          result.data?.doc?.peerFinancialData ?? []).length == 0
          ? []
          : [...(result.data?.doc?.peerFinancialData ?? [])]
        : (result.data?.doc?.peerEntityFinancialData ?? []).length == 0
        ? []
        : [
            ...(result.data?.doc?.entityFinancialData ?? []),
            ...(result.data?.doc?.peerEntityFinancialData ?? []),
          ];
      detailSomeData(result);
      getFlowData(result);
      getQualityDistribution(false,result.data?.sector);
    }
  };
  const detailSomeData = (res) => {
    let {
      data: { doc },
    } = res;
    const result = {};
    doc?.fiCharts?.anchorsChart?.data?.forEach((row) => {
      const businessType = row[0]; // 获取业务类型
      const columnValue = row[1]; // 获取第二列的值
      const lastValue = parseInt(row[row.length - 1], 10); // 获取每行的最后一列的值并转为整数

      // 如果result中还没有这个业务类型，则添加
      if (!result[businessType]) {
        result[businessType] = { columns: [], data: [] };
      }

      // 将columnValue添加到columns数组中（如果不存在的话）
      if (!result[businessType].columns.includes(columnValue)) {
        result[businessType].columns.push(columnValue);
      }

      // 将lastValue添加到data数组中
      result[businessType].data.push(lastValue);
    });
    setCurrentAllData(result);
  };
  const getAdvancedInfo = async () => {
    await api.getCompanyAdvancedInfo({
      companyId,
      industries: industriesId,
      quality: qualityId,
    });
  };
  const getQualityChange = async () => {
    const result = await api.getCompanyQualityChange({ companyId });
    if (result?.code == 200) {
      let dateArr = getLastYearMonthArray();
      let defaultNum = null;
      if (result?.data && result?.data?.length > 0) {
        defaultNum = result?.data[0].quality;
      }
      let arr1 = dateArr.map(
        (time) =>
          result?.data?.find((item) => item.lastQualityTime == time)?.quality ||
          defaultNum
      );
      let arr = dateArr.map(
        (time) => {
          return {
            quality:  result?.data?.find((item) => item.lastQualityTime == time)?.quality ||
            defaultNum,
            isReport:result?.data?.find((item) => item.lastQualityTime == time)?.isReport ||
            false,
          }
        }
        
      );
      let changeIndexArr = comparTwoData(arr);
    }
  };
  const getQualityDistribution = async (flag = false,sector) => {
    await api.getCompanyQualityDistribution(
      flag ? { industries: industriesId,sector } : {sector}
    );
  };
  const getOpinionList = async () => {
    await api.getCompanyOpinionList({
      companyId,
      current: 1,
      size: 20,
    });
  };
  const menus_creditDetail = {
    menus: [
		{
		  title: "PCA报告",
		  key: "pca_pdf",
		  href: "#pca_pdf",
		},
      {
        title: "PCA框架",
        key: "pca_credit",
        href: "#pca_credit",
        children: [{ title: "分析概述", key: "overview", href: "#overview" }],
      },
      {
        title: "风险舆情",
        key: "opinion",
        href: "#opinion",
        children: [
          { title: "相关舆情点评", key: "opinion_review", href: "#opinion1" },
          { title: "半年内主体信用质量变动", key: "rating_changes", href: "#opinion2" },
        ],
      },
      {
        title: "信用质量分布",
        key: "mass_distribution",
        href: "#mass_distribution",
      },
      {
        title: "财务分析",
        key: "financial_analysis",
        href: "#financial_analysis",
      },
      // { title: "压力测试", key: "stress_testing" , href: "#stress_testing"},
      { title: "同业比较", key: "comparison", href: "#comparison" },
    ],
    defaultActiveKey: "overview",
    defaultOpenSubKey: ["pca_credit", "opinion"],
  };

  useEffect(() => {
    isSmillScreen = window.innerHeight <= 780
	getDetailPdf()
    getCompanyDetailInfo();
    getAdvancedInfo();
    getQualityChange();
    getOpinionList();
  }, []);

  const getFlowData = (res) => {
    let dataArr = [];
    let {
      data: { doc },
    } = res;
    if (res.data?.sector == "金融机构") {
      dataArr.push(
        detailNumberValue(doc?.businessProfile ? doc?.businessProfile : ""),
        detailNumberValue(
          doc?.capitalProfitability ? doc?.capitalProfitability : ""
        ),
        detailNumberValue(
          doc?.financingLiquidity ? doc?.financingLiquidity : ""
        ),
        detailNumberValue(doc?.riskProfile ? doc?.riskProfile : "")
      );
      setCurrentRardaData(dataArr);
    } else {
      dataArr.push(
        detailNumberValue(doc?.industryRisk ? doc?.industryRisk : ""),
        detailNumberValue(
          doc?.competitivePosition ? doc?.competitivePosition : ""
        ),
        detailNumberValue(doc?.brp ? doc?.brp : ""),
        detailNumberValue(doc?.frp ? doc?.frp : "")
      );
      setCurrentRardaData(dataArr);
    }
    chartsData(null, null, dataArr, res.data.sector);
  };

  const chartsData = (id, d, dataArr, sector, currentInfo) => {
    let isJRFlag = false;
    if (sector && sector == "金融机构") {
      isJRFlag = true;
    } else if (isFIType) {
      isJRFlag = true;
    }
    let currentChartKey = !chartKey;
    let flag = false;
    Object.keys(currentAllData).map((ele) => {
      if (ele == d) {
        flag = true;
      }
    });
    if (id == null || flag == false) {
      let final_arr = dataArr ? dataArr : currentRardaData;
      let is_final_arr = isJRFlag ? final_arr.map((number) =>  number) : final_arr.map((number) =>  -number);
      let options = {
        tooltip: {
          className: style.echarts_outer,
          confine: true,
          enterable: false, //鼠标是否可以移动到tooltip区域内
          formatter: (a, b, c) => {
            let {
              data: { name, value },
            } = a;
            let str = "";
            value.map((item, index) => {
              str += `<div style="display:flex;align-items:center;justify-content:space-between">
                 <div style="display:flex;align-items:center;margin-right:6px">
                   <div style="width:16px;height:16px;border-radius:2px;background:#5B8FF9;margin-right:8px"></div>
                   <div style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 12px;color: #000000;">${
                     name[index]
                   }</div>
                 </div>
                 <div style="font-family: HelveticaNeue, HelveticaNeue;font-weight: 500;font-size: 12px;color: #000000;">${
                   isJRFlag ? item : -item
                 }</div>
                </div>`;
            });
            return ` <div style="width:100%;height:100%">
            ${str}
              </div>`;
          },
        },
        radar: {
          indicator: isJRFlag
            ? [
                {
                  name: "业务状况",
                  min: -3,
                  max: 3,
                  axisLabel: {
                    show: true,
                    inside: true,
                    fontSize: 12,
                    formatter: (value, a) => {
                      return value;
                    },
                    color: "rgba(0, 0, 0, 0.45)",
                    // showMaxLabel: false, //不显示最大值，即外圈不显示数字30
                    showMinLabel: true, //显示最小数字，即中心点显示0
                  },
                },
                { name: "资本与盈利性", min: -5, max: 2 },
                { name: "融资流动性", min: -3, max: 2 },
                { name: "风险状况", min: -5, max: 2 },
              ]
            : [
                {
                  name: "行业风险",
                  max: -1,
                  min: -5,
                  axisLabel: {
                    show: true,
                    inside: true,
                    fontSize: 12,
                    formatter: (value, a) => {
                      return -value;
                    },
                    color: "rgba(0, 0, 0, 0.45)",
                    // showMaxLabel: false, //不显示最大值，即外圈不显示数字30
                    showMinLabel: true, //显示最小数字，即中心点显示0
                  },
                },
                { name: "竞争地位", max: -1, min: -6 },
                { name: "业务状况", max: -1, min: -6 },
                { name: "财务风险", max: -1, min: -6 },
              ],
          splitNumber: isJRFlag ? 4 : 5, // 这里设置了5个刻度环
          radius:isSmillScreen ? ["0%","65%"] : ['0%','75%'],
          nameGap:10,
          axisLabel: {
            margin: 3,
            verticalAlign: "top",
            align: "right",
          },
          axisName:{
            fontSize:isSmillScreen ? 9 : 12
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgba(255,255,255,0)", // 图表背景的颜色
            },
          },
        },
        series: [
          {
            type: "radar",
            areaStyle: {
              normal: {
                width: 1,
                opacity: 0.2,
              },
            },
            data: [
              {
                value: is_final_arr,
                name: isJRFlag
                ?["业务状况","资本与盈利性","融资流动性","风险状况"] : ["行业风险", "竞争地位", "业务状况", "财务风险"],
                itemStyle: {
                  normal: {
                    color: "rgba(91,143,249,.9)",
                  },
                },
              },
            ],
          },
        ],
      };
    } else {
      let ccolumns = [...currentAllData[d]?.columns];
      let carr = deepCopy(currentAllData[d]?.data);
      let findIndex = ccolumns.findIndex((item) => item == currentInfo);
      carr[findIndex] = {
        value: carr[findIndex],
        itemStyle: { color: " #33D695" },
      };
      let options = {
        title: {
          show: true, //false
          text: "行业内主体数量", //主标题文本
          textStyle: {
            color: "#B0B5B9", //'red'，字体颜色
            fontWeight: "400", //'bold'(粗体) | 'bolder'(粗体) | 'lighter'(正常粗细) ，字体粗细
            fontFamily: "PingFangSC, PingFang SC", //'sans-serif' | 'serif' | 'monospace' | 'Arial' | 'Courier New'
            fontSize: 12, //字体大小
            lineHeight: 12, //字体行高
          },
          textAlign: "auto", //整体（包括 text 和 subtext）的水平对齐
          textVerticalAlign: "auto", //整体（包括 text 和 subtext）的垂直对齐
          padding: 0, //[5,10] | [ 5,6, 7, 8] ,标题内边距
          left: "2.5%", //'5' | '5%'，title 组件离容器左侧的距离
          top: "30", //title 组件离容器上侧的距离
        },
        grid: {
          left: "14%",
          bottom:25,
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: ccolumns,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: carr,
            type: "bar",
          },
        ],
      };
      setChartKey(currentChartKey);
    }
  };

  const changeCurrentSelectId = (f, d, a, b, currentInfo) => {
    chartsData(f, d, undefined, undefined, currentInfo);
  };

  return (
    <>
		<div>
			{ pdfData?.sector === '工商企业' && <OldDetail data={pdfData} companyId={companyId} /> }
			{ pdfData?.sector !== '工商企业' && pdfData?.doc.industryCategory === '银行' && <BankDetail data={pdfData} companyId={companyId} /> }
			{ pdfData?.sector !== '工商企业' && pdfData?.doc.industryCategory === '保险公司' && <InsuranceDetail data={pdfData} companyId={companyId} /> }
			{ pdfData?.sector !== '工商企业' && (pdfData?.doc.industryCategory !== '银行' && pdfData?.doc.industryCategory !== '保险公司') && <BankDetail data={pdfData} companyId={companyId} /> }
		</div>
    </>
  );
};

export default QueryView;