import axios from 'axios'
import ApiConstants from '../../constants/apiConstants'
import '../../mock/mock'
import { isObject } from '../index'
import { message } from 'antd';

const request = axios.create({
  headers: {
    "Content-Type": "application/json"
  },
  baseURL: ApiConstants.HOST_NAME,
  timeout: 1000*60*10, // request timeout
})

request.interceptors.request.use(function (config) {
  if(config.paramsConfig) {
    Object.keys(config.paramsConfig).map(_k => {
      if(isObject(config.paramsConfig[_k]))
        config[_k] = Object.assign(config[_k], config.paramsConfig[_k])
        else config[_k] = config.paramsConfig[_k]
    })
  }
  config.headers['satoken'] = localStorage.getItem('pca_token')
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  const { data, status } = response
  if(status==200){
    if(data.code==200) return Promise.resolve(data)
    else if(data.code==403){
        sessionStorage.clear();
        localStorage.removeItem('pca_token');
        localStorage.removeItem('pca_user');
        window.location.reload()
    }
    else {
      message.error(data.message)
      return Promise.resolve(data)
    }
  } else return message.error('请重试')
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  const {status} = error.response
  if(status!==200) return message.error('请重试请求')
  return Promise.reject(error);
});

export default request