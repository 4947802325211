import React, {useEffect, useState} from "react";
import {Graph} from "@antv/x6";
import {register, Portal} from "@antv/x6-react-shape";
import style from "./index.module.scss";
import icon1 from "@/assets/icons/icon1.png";
import {Spin, Tooltip} from "antd";
import icon2 from "@/assets/icons/icon2.png";

const X6ReactPortalProvider = Portal.getProvider(); // 注意，一个 graph 只能申明一个 portal provider
const CurrentIndexContext = React.createContext({
  state: {
    /* 初始状态 */
  },
});
let statusArr = [
  "aaa1",
  "aaa2",
  "aaa3",
  "aaa4",
  "aaa5",
  "aa+1",
  "aa+2",
  "aa",
  "aa-",
  // "a+",
  "a",
];
let HYallowArr = ["业务状况", "竞争地位", "财务风险"];
let FIallwoArr = [
  "行业风险",
  "业务状况",
  "经济风险",
  "资本与盈利性",
  "风险状况",
  "融资与流动性",
  "评级基准",
  "补充调整",
  "个体信用质量",
  "外部影响",
  "主体信用质量",
  "调整项：管理与治理",
  "调整项：流动性",
  "业务风险",
  "财务风险",
  "竞争地位",
  "财务灵活性",
];
const IS_SAFARI = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
const NodeComponent = (props) => {
  let {
    idx,
    currentInfo,
    currentName,
    currentId,
    children,
    old_info,
    new_info,
    removeAdd,
    sector,
    industryCategory,
    industrySubcategory
  } =
    props.node.store.data.data;
  const context = React.useContext(CurrentIndexContext);
  let {currentIndex, nodeClick, isFIType} = context.state;
  let canChoose = true;
  let allowArr = !isFIType ? HYallowArr : FIallwoArr;
  let newRules = false
  if (!allowArr.includes(currentName)) {
    canChoose = false;
  }
  if (
    currentName == "评级基准" ||
    currentName == "个体信用质量" ||
    currentName == "主体信用质量" ||
    currentName == "行业风险" ||
    currentName == "经济风险" ||
    currentName == "外部影响" ||
    currentName == "补充调整"
  ) {
    canChoose = false;
  }
  if (sector === '金融机构' && industryCategory === '保险公司') {
    newRules = true
    canChoose = false;
    let openNode = ['业务风险', '财务风险']
    if (openNode.indexOf(currentName) >= 0) canChoose = true
  }
  let noShowType = ['工商企业']
  if (noShowType.indexOf(sector) >= 0 || (sector === '金融机构' && industrySubcategory === '政策性银行/开发性金融机构')) {
    canChoose = false;
  }
  if (industrySubcategory === '其他') {
    canChoose = false;
  }
  const getColor = (aItem) => {
    const a_number = aItem.match(/a/gi);
    const b_number = aItem.match(/b/gi);
    if (a_number && a_number?.length) {
      switch (a_number?.length) {
        case 3:
          return '#06927C'
        case 2:
          return '#08C3A5'
        case 1:
          return '#BEDACA'
      }
    } else if (b_number && b_number?.length) {
      switch (b_number?.length) {
        case 3:
          return '#FFAC17'
        case 2:
          return '#FF0000'
        case 1:
          return '#930F4B'
      }
    } else {
      return '#930F4B'
    }
  }
  const getInfo = () => {
    if (currentInfo === '') {
      return;
    }
    let infoId = parseInt(currentInfo);
    if (currentName == '主体信用质量') {
      return <div className={style.nodeOuterFNumber}
                  style={{backgroundColor: getColor(old_info), width: "50px"}}>{old_info}</div>;
    }
    if (infoId >= 0) {
      if (removeAdd) {
        return (
          <div className={style.nodeOuterZNumber} style={IS_SAFARI ? {marginLeft: "auto"} : {}}>
            <>
              {currentInfo}
            </>
          </div>
        );
      } else {
        return (
          <div className={style.nodeOuterZNumber} style={IS_SAFARI ? {marginLeft: "auto"} : {}}>
            {
              newRules ?
                <>
                  {currentName === '补充调整' ? "+" : ""}
                  {currentInfo}
                </> :
                currentName == "行业风险" ?
                  <>
                    {currentInfo}
                  </>
                  :
                  <>
                    {props.data?.sector !== '工商企业' && currentInfo == 0 ? "" : "+"}
                    {currentInfo}
                  </>
            }

          </div>
        );
      }

    } else if (statusArr.includes(currentInfo)) {
      return <div className={style.nodeOuterZNumber} style={IS_SAFARI ? {marginLeft: "auto"} : {}}>{currentInfo}</div>;
    } else {
      return <div className={style.nodeOuterFNumber} style={IS_SAFARI ? {marginLeft: "auto"} : {}}>{currentInfo}</div>;
    }
  };
  return (
    <div
      onClick={($event) => {
        if (canChoose) {
          nodeClick(idx, currentId, children, currentName, currentInfo, $event);
        }
      }}
      className={
        currentName == "现金流/财务杠杆"
          ? style.nodeOuter2Style
          : style.nodeOuterStyle
      }
      style={
        !canChoose
          ?
          Object.assign({}, {
            backgroundColor: "#F2F3F6",
            borderColor: "#ECF0F1",
            cursor: "not-allowed",
            boxShadow: "none",
          }, IS_SAFARI ? {
            position: 'static',
            padding: '0',
          } : {})
          : idx == currentIndex
            ? {
              borderColor: "#044DCC",
              background: "#E9F2FF",
              position: IS_SAFARI ? 'static' : 'relative',
              padding: IS_SAFARI ? '0' : '8px'
            }
            : {
              borderColor: "#ECF0F1",
              background: "#ffffff",
              position: IS_SAFARI ? 'static' : 'relative',
              padding: IS_SAFARI ? '0' : '8px'
            }
      }
    >
      <Tooltip placement="right" title={old_info}>
        <div
          className={style.nodeOuterName}
          style={
            !canChoose
              ? Object.assign({}, {color: "rgba(0,0,0,0.45)"}, IS_SAFARI ? {marginLeft: "8px"} : {})
              : idx == currentIndex
                ? Object.assign({}, {color: "#044DCC"}, IS_SAFARI ? {marginLeft: "8px"} : {})
                : Object.assign({}, {color: "rgba(0,0,0,0.85)"}, IS_SAFARI ? {marginLeft: "8px"} : {})
          }
        >
          {currentName}
        </div>
      </Tooltip>

      {!isFIType
        ? getInfo(currentInfo)
        : allowArr.includes(currentName)
          ? getInfo(currentInfo)
          : null}
      <Tooltip placement="right" title={new_info}>
        {idx == currentIndex ? (
          <img src={icon1} className={IS_SAFARI ? style.nodeOuterImgForSafari : style.nodeOuterImg}></img>
        ) : (
          <img src={icon2} className={IS_SAFARI ? style.nodeOuterImgForSafari : style.nodeOuterImg}></img>
        )}
      </Tooltip>
    </div>
  );
};

register({
  width: 114,
  height: 36,
  shape: "org-node",
  component: NodeComponent,
});

register({
  width: 147,
  height: 36,
  shape: "org-node-2",
  component: NodeComponent,
});

// 布局方向
const dir = "LR"; // LR RL TB BT

Graph.registerEdge(
  "org-edge",
  {
    zIndex: -1,
    attrs: {
      line: {
        fill: "none",
        strokeLinejoin: "round",
        strokeWidth: 2,
        stroke: "#A2B1C3",
        sourceMarker: null,
        targetMarker: null,
      },
    },
  },
  true
);

function FlowCpn(props) {
  let {isFIType} = props;
  let timers = [];
  let click_timers = null;
  const [container, setcontainer] = useState(document.createElement("div"));
  const [currentIndex, setCurrentIndex] = useState(null);
  const [graphState, setGraphState] = useState(null);

  const refContainer = (container) => {
    setcontainer(container);
  };
  useEffect(() => {
    let arr = [];
    let linkInfoArr = [];
    let data = props.flowData;
    const graph = new Graph({
      container: container,
      connecting: {
        router: "normal",
        snap: false,
      },
      panning: {
        enabled: false,
      },
      interacting: false,
    });

    function member(x, y, name, info, id, children, old_info, new_info, removeAdd, industryCategory, sector, industrySubcategory) {
      if (name == "现金流/财务杠杆") {
        return graph.addNode({
          x,
          y,
          shape: "org-node-2",
          data: {
            idx: id,
            currentName: name,
            currentInfo: info,
            currentId: id,
            children: children,
            old_info: old_info,
            new_info: new_info,
            removeAdd: removeAdd,
            industryCategory,
            sector,
            industrySubcategory,
          },
        });
      } else {
        return graph.addNode({
          x,
          y,
          shape: "org-node",
          data: {
            idx: id,
            currentName: name,
            currentInfo: info,
            currentId: id,
            children: children,
            old_info: old_info,
            new_info: new_info,
            removeAdd: removeAdd,
            industryCategory,
            sector,
            industrySubcategory,
          },
        });
      }
    }

    function link(source, target, vertices) {
      return graph.addEdge({
        vertices,
        source: {
          cell: source,
        },
        target: {
          cell: target,
        },
        shape: "org-edge",
      });
    }

    data.map((item) => {
      let x = item.x;
      let y = item.y;
      let name = item.name;
      let info = item.info;
      let old_info = item.old_info;
      let new_info = item.new_info;
      let id = item.id;
      let children = item.children;
      let removeAdd = item.removeAdd
      let industryCategory = item.industryCategory
      let sector = item.sector
      let industrySubcategory = item.industrySubcategory
      item.linkInfo.map((ele) => {
        let param = {
          startId: id,
          endId: ele,
        };
        linkInfoArr.push(param);
      });
      let params = member(x, y, name, info, id, children, old_info, new_info, removeAdd, industryCategory, sector, industrySubcategory);
      arr.push(params);
    });
    linkInfoArr.map((ele) => {
      let {startId, endId} = ele;
      link(arr[startId], arr[endId]);
    });

    const edges = graph.getEdges();
    edges.forEach((edge, index) => {
      const source = edge.getSourceNode();
      const target = edge.getTargetNode();
      const sourceBBox = source.getBBox();
      const targetBBox = target.getBBox();
      if ((dir === "LR" || dir === "RL") && sourceBBox.y !== targetBBox.y) {
        const gap =
          dir === "LR"
            ? targetBBox.x - sourceBBox.x - sourceBBox.width
            : -sourceBBox.x + targetBBox.x + targetBBox.width;
        const fix = dir === "LR" ? sourceBBox.width : 0;
        let x = sourceBBox.x + fix + gap / 2;
        edge.setVertices([
          {x, y: sourceBBox.center.y},
          {x, y: targetBBox.center.y},
        ]);
      } else if (
        (dir === "TB" || dir === "BT") &&
        sourceBBox.x !== targetBBox.x
      ) {
        const gap =
          dir === "TB"
            ? targetBBox.y - sourceBBox.y - sourceBBox.height
            : -sourceBBox.y + targetBBox.y + targetBBox.height;
        const fix = dir === "TB" ? sourceBBox.height : 0;
        const y = sourceBBox.y + fix + gap / 2;
        edge.setVertices([
          {x: sourceBBox.center.x, y},
          {x: targetBBox.center.x, y},
        ]);
      } else {
        edge.setVertices([]);
      }
      edge.attr("line", {
        stroke: "rgba(158,188,255,1)", //颜色rgb
        strokeDasharray: 6, //虚线
        targetMarker: IS_SAFARI ? {} : {
          name: "classic",
          size: 6, //箭头大小
        },
        strokeWidth: 1, //线条宽度
        style: {
          animation: "ant-line 30s infinite linear", //动画效果
        },
      });
      let timer = setTimeout(() => {
        // edge.attr('line',
        //   {
        //         stroke: "red",  //颜色rgb
        //         strokeDasharray: 6,  //虚线
        //         targetMarker: {
        //             name: 'classic',
        //             size: 6          //箭头大小
        //         },
        //         strokeWidth: 1,    //线条宽度
        //         style: {
        //             animation: 'ant-line 30s infinite linear',  //动画效果
        //         },
        //     })
        edge.attr("line/strokeDasharray", "");
        edge.attr("line/style/animation", "");
        clearTimeout(timers[index]);
      }, index * 200);
      timers.push(timers);
    });
    graph.zoomToFit();
    setGraphState(graph);
  }, [container, props.flowData]);
  useEffect(() => {
    if (!props.chooseZb) {
      setCurrentIndex(null)
    }
  }, [props.chooseZb])

  const nodeClick = (e, f, c, d, currentInfo, event) => {
    let userInfo = localStorage.getItem('pca_user')? JSON.parse(localStorage.getItem('pca_user')) : {}
    // console.log("_trackEvent", "PCA信用分析框架",userInfo?.account,`${d}-${props.data?.entityName}`,event?.view?.location?.href)
    window._czc.push(["_trackEvent", "PCA信用分析框架",userInfo?.account,`${d}-${props.data?.entityName}`,event?.view?.location?.href]);
    if (click_timers) {
      clearTimeout(click_timers);
    }
    const edges = graphState.getEdges();
    edges.forEach((edge, index) => {
      if (c.includes(index)) {
        edge.attr("line", {
          stroke: "rgba(158,188,255,1)", //颜色rgb
          strokeDasharray: 6, //虚线
          targetMarker: IS_SAFARI ? {} : {
            name: "classic",
            size: 6, //箭头大小
          },
          strokeWidth: 1, //线条宽度
          style: {
            animation: "ant-line 30s  linear", //动画效果
          },
        });
        let click_timer = setTimeout(() => {
          edge.attr("line/strokeDasharray", "");
          edge.attr("line/style/animation", "");
        }, 1000);
        click_timers = click_timer;
      }
    });
    if (f == currentIndex) {
      props.changeCurrentSelectId(null, null);
      setCurrentIndex(null);
    } else {
      props.changeCurrentSelectId(f, d, undefined, undefined, currentInfo);
      setCurrentIndex(e);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Spin spinning={props.flowLoading}>
        <CurrentIndexContext.Provider
          value={{
            state: {
              currentIndex,
              isFIType,
              nodeClick,
            },
          }}
        >
          <X6ReactPortalProvider/>
        </CurrentIndexContext.Provider>

        <div
          id="container"
          style={{width: "100%", height: "100%"}}
          ref={refContainer}
        ></div>
      </Spin>
    </div>
  );
}

export default FlowCpn;