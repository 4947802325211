import React, {useState, useEffect} from 'react'
import {Table, Select} from 'antd'
import {ArrowRightOutlined} from '@ant-design/icons'
import {deepCopy, isArray, isObject, formatNumberWithCommas, removeCommas} from '@/utils'
import IndicatorsAndComparisonBarChart from "./IndicatorsAndComparisonBarChart";
import Style from './IndicatorsAndComparisonTableList.module.scss'

const IndicatorsAndComparisonTableList = ({data = {},indicatorsAndComparisonBarChartData = {}}) => {
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [isShowEcharts, setShowEcharts] = useState(false)
  const [showEchartsData, setShowEchartsData] = useState({})
  const [selectedData, setSelectedData] = useState([])
  const [selectValue, setSelectValue] = useState('')
  const [ showEchartsType, setShowEchartsType ] = useState('')
  const [ sector, setSector ] = useState('')
  useEffect(() => {
    init(2)
  }, [tableData])
  useEffect(() => {
    setSector(data?.sector)
    init(1)
  }, [])
  const init = (type) => {
    const copyData = deepCopy(JSON.parse(JSON.stringify(data).replace(/icrSub/g, "主体信用质量")))
    const selfData = copyData?.doc?.entityFinancialData
    const diffData = copyData?.doc?.peerEntityFinancialData || copyData?.doc?.peerFinancialData || []
    let yearStr = ''
    if (isArray(selfData) && selfData?.length > 0) {
      yearStr = [...selfData, ...diffData].reduce((acc, cur) => {
        if (!acc[cur.entityId]) {
          acc[cur.entityId] = cur['年份']
          return acc
        } else {
          acc[cur.entityId] = acc[cur.entityId] + '/' + cur['年份']
          return acc
        }
      }, {})
      const newData = [...selfData, ...diffData].reduce((acc, cur) => {
        if (!acc[cur.entityId]) {
          let obj = {}
          obj[cur.entityId] = cur
          obj[cur.entityId]['年份'] = yearStr[cur.entityId]
          return Object.assign({}, acc, obj)
        } else {
          acc[cur.entityId].financialIndicator = acc[cur.entityId].financialIndicator.map((item, index) => {
            item.indicatorValue = (Number(removeCommas(item.indicatorValue)) + Number(removeCommas(cur.financialIndicator[index]?.indicatorValue))) / 2
            return item
          })
          return acc
        }
      }, {})
      if (type === 1) getKeyIndicatorsTableData(deepCopy(newData), 'normal')
      if (type === 2) getKeyIndicatorsColumns(deepCopy(newData), 'normal', copyData?.doc?.entityId)
    } else if (isObject(selfData) && selfData.financialIndicator.length > 0) {
      yearStr = [...[selfData], ...diffData].reduce((acc, cur, index) => {
        acc[index] = cur['财务指标年度']
        return acc
      }, {})
      const result = [selfData];
      const newData = [...result, ...diffData].reduce((acc, cur, index) => {
        // 没有 entityId 的情况下
        let obj = {}
        obj[cur['中文全称']] = cur
        obj[cur['中文全称']]['财务指标年度'] = yearStr[index]
        return Object.assign({}, acc, obj)
      }, {})
      if (type === 1) getKeyIndicatorsTableData(deepCopy(newData), 'special')
      if (type === 2) getKeyIndicatorsColumns(deepCopy(newData), 'special', copyData?.doc?.entityName)
    }
  }
  const getKeyIndicatorsColumns = (data = [], type, ownID) => {
    const newData = Object.values(data)
    let columns = []
    if (type === 'normal') {
      columns = newData.map((item) => {
        return {
          title: item['年份'],
          dataIndex: item.entityId,
          key: item.entityId,
        }
      })
    } else if (type === 'special') {
      columns = newData.map((item) => {
        return {
          title: item['财务指标年度'],
          dataIndex: item['中文全称'],
          key: item['中文全称'],
        }
      })
    }
    columns.unshift({
      title: sector === '工商企业'?'同业比较（两年平均）':'同业比较',
      dataIndex: 'comparative',
      key: 'comparative'
    })

    const newColumns = moveColumnToFirst(ownID, columns)
    newColumns.push({
      title: " ",
      dataIndex: 'pro',
      key: 'pro',
      render: (text, record) => {
        return (record.comparative !== " " && record.comparative !== '主体名称' && record.comparative !== '主体信用质量') &&
          <div style={{color: '#3866E7', cursor: 'pointer', fontSize: '12px', whiteSpace: 'nowrap'}}
               onClick={() => {
                 echartsData(record)
               }}><ArrowRightOutlined/> 查看图表</div>
      }
    })
    setColumns(newColumns)
  }
  const echartsData = (record) => {
    setShowEchartsType(record.comparative)
    let configDataSelect = []
    tableData.map(item => {
      if (item.comparative !== ' ' && item.comparative !== '主体名称' && item.comparative !== '主体信用质量') configDataSelect.push({
        label: item.comparative,
        value: item.comparative
      })
    })
    setSelectValue(record.comparative)
    setSelectedData(configDataSelect)
    setShowEcharts(true)
  }
  const getKeyIndicatorsTableData = (data = [], type) => {
    const newData = Object.values(data)
    if (type === 'normal') {
      setTableData(transformData(newData))
    } else if (type === 'special') {
      setTableData(transformData_special(newData))
    }
  }

  // 转换函数
  const transformData = (input) => {
    const companyNames = {};
    const financialIndicators = [];
    input.forEach(item => {
      // 收集企业名称
      companyNames[item.entityId] = item.企业名称;

      // 收集财务指标
      item.financialIndicator.forEach((indicator, index) => {
        const indicatorIndex = financialIndicators.findIndex(fin => fin.comparative === indicator.indicatorName);
        if (indicatorIndex === -1) {
          financialIndicators.push({
            [item.entityId]: formatNumberWithCommas(indicator.indicatorValue),
            key: index + 1,
            comparative: indicator.indicatorName,
          });
        } else {
          financialIndicators[indicatorIndex][item.entityId] = formatNumberWithCommas(indicator.indicatorValue);
        }
      });
    });

    // 添加第一行企业名称
    const firstRow = {
      key: 0,
      comparative: data?.sector === '工商企业'?'主体名称':' ',
      ...companyNames
    };

    financialIndicators.unshift(firstRow);


    return financialIndicators;
  };

  const transformData_special = (cData) => {
    // 初始化结果数组
    const result = [];

    // 添加银行名称行
    const bankNamesRow = {
      key: 0,
      comparative: " ",
    };

    // 添加银行数据
    cData.forEach(bank => {
      bankNamesRow[bank['中文全称']] = bank['中文简称'];
    });
    if(data?.sector === '工商企业') bankNamesRow.comparative = '主体名称'
    result.push(bankNamesRow);

    // 处理各个财务指标
    const indicators = cData[0].financialIndicator.map(ind => ({
      name: ind.indicatorName,
      values: {}
    }));

    cData.forEach(bank => {
      bank.financialIndicator.forEach(ind => {
        const indicatorEntry = indicators.find(i => i.name === ind.indicatorName);
        if (indicatorEntry) {
          indicatorEntry.values[bank['中文全称']] = formatNumberWithCommas(ind.indicatorValue);
        }
      });
    });
    // 构建结果
    indicators.forEach((indicator, index) => {
      const indicatorRow = {
        key: index + 1,
        comparative: indicator.name,
      };
      Object.entries(indicator.values).forEach(([bankName, value]) => {
        indicatorRow[bankName] = value;
      });
      result.push(indicatorRow);
    });
    return result;
  }

  const moveColumnToFirst = (columnDataIndex, columns) => {
    const newColumns = columns.slice();
    const targetColumn = newColumns.find((col) => col.dataIndex === columnDataIndex);
    if (targetColumn) {
      newColumns.splice(newColumns.indexOf(targetColumn), 1);
      // newColumns.unshift(targetColumn);
      newColumns.splice(1, 0, targetColumn)
    }
    return newColumns;
  };
  const selectChange = (e) => {
    tableData.map(item => {
      if (e === item.comparative) echartsData(item)
    })
  }
  return (
    <div className={Style.IndicatorsAndComparisonTableList_table}>
      {isShowEcharts && <div className={Style.backStyle} onClick={() => {
        setShowEcharts(false)
      }}><img src={require('@/assets/images/back.png')} width={15} alt="" style={{marginRight: '5px'}}/>返回表格
      </div>}
      {
        isShowEcharts && <Select
          className={Style.echartsSelect}
          onChange={(e) => {
            selectChange(e)
          }}
          defaultValue={selectValue}
          style={{width: 220}}
          options={selectedData}
        />
      }
      {
        isShowEcharts ? <IndicatorsAndComparisonBarChart
          basicData={data}
          data={indicatorsAndComparisonBarChartData}
          showEchartsType={showEchartsType}
        /> : <Table
          size="middle"
          bordered
          rowKey={(record) => record.key}
          pagination={false}
          columns={columns}
          dataSource={tableData}
        />
      }
    </div>
  )
}

export default IndicatorsAndComparisonTableList